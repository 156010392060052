<mat-sidenav-container
*ngIf="isHandset$ | async"
class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="'dialog'"
      [mode]="'over'"
      [opened]="false"
      position="end">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <button [routerLink]="'/index'" class="sidenav-button" id="basic" type="button" mat-button>
        Domov
      </button>
      <button [routerLink]="'/info'" class="sidenav-button" id="basic" type="button" mat-button>
        Čo robíme?
      </button>
      <button [routerLink]="'/catalog'" class="sidenav-button" id="basic" type="button" mat-button>
        Čo ďalšie ponúkame?
      </button>
      <button [routerLink]="'/info2'" class="sidenav-button" id="basic" type="button" mat-button>
        Ako sme užitoční?
      </button>
      <button [routerLink]="'/contact'" class="sidenav-button" id="basic" type="button" mat-button>
        Kontakt
      </button>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="nav-top">
        <span>
          <img
        src="../../assets/img/keseco-logo.png"
        class=logo
        />
        </span>
        <button
        class="nav-toggle-button"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
    </mat-toolbar>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-toolbar
*ngIf="!(isHandset$ | async)"
class="nav-top"
>
    <span>
      <img
      src="../../assets/img/keseco-logo.png"
      class=logo
      [routerLink]="'/index'"
      />
    </span>
    <span>
      <button [routerLink]="'/index'" class="nav-button" id="basic" type="button" mat-button>
        Domov
      </button>
      <button [routerLink]="'/info'" class="nav-button" id="basic" type="button" mat-button>
        Čo robíme?
      </button>
      <button [routerLink]="'/catalog'" class="nav-button" id="basic" type="button" mat-button>
        Čo ďalšie ponúkame?
      </button>
      <button [routerLink]="'/info2'" class="nav-button" id="basic" type="button" mat-button>
        Ako sme užitočni?
      </button>
      <button [routerLink]="'/contact'" class="nav-button" id="basic" type="button" mat-button>
        Kontakt
      </button>
    </span>
</mat-toolbar>
<router-outlet></router-outlet>