import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { VimeModule } from '@vime/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ExamplesComponent } from './examples/examples.component';
import { PropertiesComponent } from './properties/properties.component';
import { CatalogComponent } from './catalog/catalog.component';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { ContactComponent } from './contact/contact.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { InfoComponent } from './info/info.component';
import { Info2Component } from './info2/info2.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: "eeua.sk"
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ExamplesComponent,
    PropertiesComponent,
    CatalogComponent,
    ContactComponent,
    InfoComponent,
    Info2Component,
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    VimeModule,
    AnimateOnScrollModule.forRoot(),
    NgScrollbarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  
 }
