import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  emailstring= "mailto:lk@kesecosk.sk";

  ngOnInit(): void {
  }

  sendMail(subject:string,message:string):void{
    this.emailstring= "mailto:lk@kesecosk.sk?Subject="+subject+"&body="+message;
    window.location.href = this.emailstring;
  }

  

}
