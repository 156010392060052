<div >
    <div class="title-div"><p>title</p></div>>
    <div class="dark-div">
        <h3>Šetríme Vaše peniaze aj životné prostredie.</h3>
    </div>
    <div animateOnScroll class="dark-div">
        <div class="text-div">
            <h1 class=" hide-on-init" animateOnScroll animationName="animate__animated animate__fadeIn">Čo robíme?</h1>
            <h2 class=" hide-on-init" animateOnScroll animationName="animate__animated animate__fadeInRight">
                Našim zákazníkom poskytujeme služby vedúce k zvyšovaniu energetickej efektivity prevádzky. Ponúkame riešenia k pasívnemu ako aj aktívnemu znižovaniu nákladov na elektrickú energiu. 
            </h2>
            <a [routerLink]="'/info'">Viac...</a>
        </div>
        <img class="div-img hide-on-init" animateOnScroll animationName="animate__animated animate__fadeIn" src="../../assets/img/vtacik0.jpeg"/>
    </div>
    <div class="light-div">
        
        <img class="div-img hide-on-init" animateOnScroll animationName="animate__animated animate__fadeIn" src="../../assets/img/kVode.JPG"/>
        <div class="text-div">
            <h1 class=" hide-on-init" animateOnScroll animationName="animate__animated animate__fadeIn">Čo ďalšie ponúkame?</h1>
            <h2 class=" hide-on-init" animateOnScroll animationName="animate__animated animate__fadeInLeft">
                Poskytujeme poradenstvo ako aj priamu realizáciu znižovania energetických nákladov. 
            </h2>
            <a [routerLink]="'/catalog'"> Viac...</a>
        </div>
        
    </div>
    <div class="dark-div">
        <div class="text-div">
            <h1 class=" hide-on-init" animateOnScroll animationName="animate__animated animate__fadeIn">Ako sme užitoční?</h1>
            <h2 class=" hide-on-init" animateOnScroll animationName="animate__animated animate__fadeInRight">
                Okrem finančnej uspory je vysledkom našej činnosti  aj preukázateľné zniženie uhlíkovej stopy čím priamo prispievame k ochrane životného prostredia.
            </h2>
            <a [routerLink]="'/info2'">Viac...</a>
        </div>
        
        <img class="div-img hide-on-init" animateOnScroll animationName="animate__animated animate__fadeIn" src="../../assets/img/randomPriroda2.jpg"/>
    </div>
    
</div>
<footer>
    <h2>Kontakt:</h2>
    <div class="text-div">
        <h2>mail: eeua@eeua.sk</h2>
        <h2>telefón: +421 951 026 043</h2>
    </div>
</footer>