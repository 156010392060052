<div class="contact-div">
    <div class="individual-divs">
        <span class="material-icons">
            phone
        </span>
        <p>telefón: +421 951 026 043</p>
    </div>
    <div class="individual-divs">
        <span class="material-icons">
            location_on
        </span>
        <p></p>
        <p>Slobody 233/17, Košice 04011</p>
    </div>
    <div class="individual-divs">
        <span class="material-icons">
            email
        </span>
        <p>eeua@eeua.sk</p>
        <p>lk@kesecosk.sk</p>
    </div>
</div>
<div class="form">
    <!-- <div>
        <input placeholder="Subject" id="subject" class="input-field" type="text"> 
        <textarea placeholder="Message" id="message" class="input-field input-field-big" type="text"></textarea>    
    </div>
    <button class="submit-button" id="basic" type="button" onclick="sendMail(subject.value,message.value)"  mat-button>
        Kontakt
    </button> -->
</div>
