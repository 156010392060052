<div class="title_div">
    <p>title</p>
</div>
<div class="light-div">
    <div class="solo-div">
        <h1>
            Čo robíme ?
        </h1>
        <h2>
            Našim zákazníkom poskytujeme služby vedúce k zvyšovaniu energetickej efektivity prevádzky. Ponúkame riešenia k pasívnemu ako aj aktívnemu znižovaniu nákladov na elektrickú energiu. 
        </h2>
        <h2>
            <h2 class="color">Pasívny spôsob</h2>- voľba vhodnejšieho typu sadzby pre odberné miesto, efektívnejšia skladba a zaťaženie spotrebičov, ako aj iné riešenia bez priameho zásahu do energetických rozvodov.
        </h2>
        <h2>
            <h2 class="color">Aktívne spôsoby</h2>- Našou hlavnou činnosťou je priame znižovanie spotreby elektrickej energie systémom na úsporu elektrickej energie „ULTRA“ juhokórejskej spoločnosti <a href="http://www.keseco.com">„KESECO LTD-CO“ Soul</a>.
        </h2>
        <h2>
            Analýza sieti s využitím certifikovaných prístrojov. 
        </h2>
        <h2>
            Odborné prehliadky a odborné skúšky vyhradených technických zariadení elektrických a systémov na ochranu pred bleskom (revízie). 
        </h2>
        <h2>
            Kontroly a revízie elektrických spotrebičov a náradia v prevádzke.
        </h2>
    </div>
    
    <img src="../../assets/img/vtacik (1).png">
    
</div>
