import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-examples',
  templateUrl: './examples.component.html',
  styleUrls: ['./examples.component.scss']
})

export class ExamplesComponent{
  current_link="turin-bank";
  buttons = [
    {
      name: 'Bank in Italy (27-Mar-2017)',
      image: "turin-bank",

    },
    
    ];
  constructor() { }

  change_image(link:string){
    this.current_link = link
  }
}