<div class="title_div">
    <p>title</p>
</div>
<div class="light-div">
    <div class="solo-div">
        <h1>
            Ako sme užitoční?
        </h1>
        <h2>
            Okrem finančnej uspory je vysledkom našej činnosti  aj preukázateľné zniženie uhlíkovej stopy čim priamo prispievame k ochrane zivotneho prostredia.
        </h2>
        <h2>
            Ochranou zivotneho prostredia firma prezentuje spoločenskú zodpovednosť, zvýši svoju dôveryhodnosť ako aj konkurenčné postavenie na trhu.  
        </h2>
        <h2>
            Organizácia sa vopred pripraví na povinnosti, ktoré budú pravdepodobne neskôr vyžadované legislatívou a dokáže ich tak implementovať bez výrazných nákladov.
        </h2>
    </div>
    
    <img src="../../assets/img/unnamed.jpg">
    
</div>
