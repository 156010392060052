<!-- <div class="product-div-main">
    <div class="product-div">
        <h1>Industrial</h1>
    </div>
    <div class="product-div">
        <h1>Commercial</h1>
    </div>
    <div class="product-div">
        <h1>Regular</h1>
    </div>
    <div class="product-div">
        <h1>Module</h1>
    </div>
</div> -->
<div class="title_div">
    <p>title</p>
</div>
<div class="dark-div">
    <div class="solo-div">
        <h1>
            Čo ďalšie ponúkame?
        </h1>
        <h2>
            Poskytujeme tiež poradenstvo ako aj priamu realizáciu iných spôsobov znižovania energetických nákladov.
        </h2>
        <h2>
            Výroba elektrickej energie z obnoviteľných zdrojov (slnko, voda, vzduch), kompenzácia jalového výkonu a iné spôsoby vedúce k úspore nákladov. 
        </h2>
    </div>
    
    <img src="../../assets/img/voda.png">
    
</div>
