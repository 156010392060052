<div class="title-div">
    <p>Zoznam príkladov využitia </p>
</div>
<div class="scroll-div" >
    <ng-scrollbar class="virtual-scroll" thumbClass="white-scrollbars">
        <cdk-virtual-scroll-viewport scrollViewport>
            <div *ngFor="let button of buttons">
                <button (click)="change_image(button.image)" class="example-button" id="basic" type="button" mat-button>
                    {{ button.name }}
                </button>
            </div>
        </cdk-virtual-scroll-viewport>
    </ng-scrollbar>
    <img src='..\assets\img\case-study\{{current_link}}.jpg' class="example-image">
</div>
<div class="footer">

</div>

