import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogComponent } from './catalog/catalog.component';
import { ContactComponent } from './contact/contact.component';
import { ExamplesComponent } from './examples/examples.component';
import { InfoComponent } from './info/info.component';
import { Info2Component } from './info2/info2.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PropertiesComponent } from './properties/properties.component';


const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'index', component: LandingPageComponent },
  { path: 'examples', component: ExamplesComponent },
  { path: 'properties', component: PropertiesComponent },
  { path: 'catalog', component: CatalogComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'info', component: InfoComponent },
  { path: 'info2', component: Info2Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
